import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import {useHistory } from 'react-router-dom';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from 'axios';



function Forgotpassword(){
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const [state , setState] = useState({
    loginemail: ''
  })

  const logingo = () => {
    history.push('/')
  }
  
  const loginUrl = "http://localhost:9650/api/forgotuser";
//   const loginUrl = "http://localhost:9650/api/forgotuser";
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }
  var postloginData = {
    "email": state.loginemail,
  //   "password": state.loginpassword,
  };
  
  let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    };
  
    const handleLoginSubmit = (e) => {
      e.preventDefault()
      if (!state.loginemail){
        MySwal.fire({
          title: <p>Please fill all fields</p>,
          didOpen: () => {
          },
        }).then(() => {
        })
    }else {
        MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading();
            axios.post(loginUrl, postloginData, axiosConfig)
              .then((response) => {
                console.log("response received: ", response);
                Swal.close();
                MySwal.fire({
                  text: "OTP has been sent to your mail", // Use text property to display message
                }).then(() => {
                  history.push('/verifyauth');
                });
              })
              .catch((err) => {
                Swal.close();
                console.log("AXIOS ERROR: ", err);
                if (err.response.data.message) {
                  return MySwal.fire(err.response.data.message);
                } else {
                  return MySwal.fire(err.response.message);
                }
              });
          },
        }).then(() => {
        });
      }
      
    }   
    
    


      return (
              <div>
   <div className="auth-main">
          <div className="auth-wrapper v1">
            <div className="auth-form">
              <div className="card my-1">
                <div className="card-body">
                  <div className="text-center">
                    <a href="#"><img src="/assets/img/logo.png" width={'200'} alt="img" /></a>
                  </div>
                  <div className="saprator my-3">
                    <span>Enter email</span>
                  </div>
                  {/* <h4 className="text-center f-w-500 mb-3">Login with your email</h4> */}
                  <div className="form-group mb-3">
                    <input type="email" className="form-control" value={state.loginemail} onChange={handleChange} id="loginemail" name="loginemail" placeholder="Email Address" />
                  </div>
                  <div className="d-flex mt-1 justify-content-between align-items-center">
                    <div className="form-check">
                      {/* <input className="form-check-input input-primary" type="checkbox" id="customCheckc1" defaultChecked /> */}
                    </div>
                  </div>
                  <div className="d-grid mt-4">
                    <button type="button" onClick={handleLoginSubmit} className="btn btn-danger">Submit</button>
                  </div>
                  <div className="d-flex justify-content-between align-items-end mt-4">
                    {/* <h6 className="f-w-500 mb-0">Don't have an Account?</h6> */}
                    <a onClick={logingo} className="link-primary">Back to login</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
              </div>
               
        );
 
  };

export default Forgotpassword;
