import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import BASE_URL from './config';
import withReactContent from 'sweetalert2-react-content';
import {useHistory } from 'react-router-dom';




function Register(){
  const MySwal = withReactContent(Swal)
  const history = useHistory();
  const baseUrl =  BASE_URL+'/register';
  const regionUrl =  BASE_URL+'/fetchRegions';
  const [reg_info, setreg] = useState([]);
  const [state , setState] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        region: ''
    })

    var body = {
      
      };

      const goregister = () => {
        history.push('/')
      }

    const getregioninfo = async (e) => {
      axios.post(regionUrl, body, axiosConfig)
      .then((response) => {
            // console.log("response received: ", response);
            if(!response){
              setreg(0);
            }else{
            const sortedArray = response.data.data;
            setreg(sortedArray);
            setreg(sortedArray);
            setreg(sortedArray);
            setreg(sortedArray);
            // console.log(sortedArray)
            }
      });
      };
  
  
      useEffect(() => {
      getregioninfo();
                // getitemsinfo();
                const initialRegionInfo = reg_info.find(info => info.region_name === state.region);
                setState(prevState => ({
                  ...prevState,
                  price: initialRegionInfo ? initialRegionInfo.price : 0,
                }));
              }, [reg_info, state.region]); 


    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }


      var postData = {
        "email": state.email,
        "password": state.password,
        "firstname": state.firstname,
        "lastname": state.lastname,
        "email": state.email,
        "phonenumber": state.phone,
        "region": state.region,
        "role": 'user'
    };

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
 
    
      const handleRegSubmit = (e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isValidEmail = (email) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        };
        if (!state.firstname || !state.lastname || !state.password|| !state.email|| !state.phone){
          MySwal.fire({
            title: <p>Please fill all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {})
        }else if(!isValidEmail(state.email)) {
          MySwal.fire({
              title: <p>Email is invalid</p>,
              didOpen: () => {},
            }).then(() => {});
      
          }else if(!passwordRegex.test(state.password)) {
            MySwal.fire({
                title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
                didOpen: () => {},
              }).then(() => {});
        }else{
            console.log(postData)
            MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading()
            axios.post(baseUrl, postData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
              Swal.close()
              Swal.fire({
                  text: 'Registration Successul. Auth code has been sent to your mail.',
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  closeOnCancel: true,
                }).then(result => {
                  const myidd = response.data.data.userId;
                  console.log(myidd);
                  window.location.href = "auth?myidd=" + myidd;
                  console.log(response);
                })
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              if(err.response.data.data){
                return  MySwal.fire({
                  title: err.response.data.data,
                  didOpen: () => {
                  },
                }).then(() => {})
              }else{
                return  MySwal.fire({
                  title: err,
                  didOpen: () => {
                  },
                }).then(() => {})
              }
            })
          },
        }).then(() => {
        })
        }
      }



      return (
        <div>
        <div className="auth-main">
               <div className="auth-wrapper v1">
                 <div className="auth-form">
                   <div className="card my-1">
                     <div className="card-body">
                       <div className="text-center">
                         <a href="#"><img src="/assets/img/logo.png" width={'200'} alt="img" /></a>
                       </div>
                       <div className="saprator my-3">
                         <span>Register</span>
                       </div>
                       <div classname = "row">
                       <div className="form-group mb-3 col-lg-3">
                         <input type="text" className="form-control" value={state.firstname} onChange={handleChange} id="firstname" name="firstname" placeholder="Full Name" />
                       </div>
                       <div className="form-group mb-3 col-lg-6">
                         <input type="text" className="form-control" value={state.lastname} onChange={handleChange} id="lastname" name="lastname" placeholder="Last Name" />
                       </div>
                       </div>
                       <div className="form-group mb-3">
                         <input type="email" className="form-control" value={state.email} onChange={handleChange} id="email" name="email" placeholder="Email Address" />
                       </div>
                       <div className="form-group mb-3">
                         <input type="email" className="form-control" maxLength={12} value={state.phone} onChange={handleChange} id="phone" name="phone" placeholder="Phone" />
                       </div>
                       <div className="form-group mb-3">
                         <input type="password" className="form-control" value={state.password} onChange={handleChange} id="password" name="password" placeholder="Create Password" />
                       </div>
                       <div className="form-group mb-3">
                         <select className="form-control" value={state.region} onChange={handleChange} id="region" name="region">
                         <option>--select Region--</option>
                                 {reg_info.map(info => (
                                              <option key={info.id} value={info.region_name}>
                                                {info.region_name}
                                              </option>
                                            ))}
                         </select>
                       </div>
                       <div className="d-flex mt-1 justify-content-between align-items-center">
                         <div className="form-check">
                         </div>
                       </div>
                       <div className="d-grid mt-4">
                         <button type="button" onClick={handleRegSubmit} className="btn btn-danger">Register</button>
                       </div>
                       <div className="d-flex justify-content-between align-items-end mt-4">
                         <h6 className="f-w-500 mb-0">Have an Account?</h6>
                         <a  onClick={goregister} className="link-primary">Login now</a>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
            
                   </div>
               
        );
 
  };

export default Register;
