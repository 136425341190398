import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import {useHistory } from 'react-router-dom';


function Errorpage(){
const history = useHistory();
const createOrder = async () => {
  try {
    Swal.fire({
      text: "Payment unsuccessful, Please Try again",
      icon: "error",
      showCancelButton: false,
      confirmButtonText: "Back to checkout",
      closeOnCancel: true,
    }).then(() => {
      history.push('/newbooking'); 
    });
  } catch (error) {
    console.log("AXIOS ERROR:", error);
    Swal.fire(error.message || "An error occurred");
  }
}

useEffect(() => {
createOrder();
}, []);

      return (
     <div>
     <Header/>
    

       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Errorpage;
