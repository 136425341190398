import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component



function Paymentsuccespage(){
  const baseUrl = "http://localhost:9650/api/createbooking";
  // const baseUrl = "http://localhost:9650/api/createbooking";
  const MySwal = withReactContent(Swal);

  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const PostData = localStorage.getItem('ccitorontopostDataString');
  const storedPostData = JSON.parse(PostData);
  console.log(storedPostData)

  const createBooking = (e) => {
    // return actions.order.capture().then(function (details) {
      // console.log("Payment completed successfully:", details);
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          console.log(storedPostData);
          axios.post(baseUrl, storedPostData, axiosConfig)
        .then((response) => {
          // console.log(PostData)
          console.log("response received: ", response);
            Swal.fire({
                title: response.data.data,
                // text: response.data.message,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Okay",
                closeOnCancel: true,
              }).then(result => {
                window.location.href="bookings";
              })
          })
          .catch((err) => {
          // console.log(PostData)
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
        },
      }).then(() => {
      })
    // });
  };

  useEffect(() => {
    createBooking();
  }, []);

      return (
     <div>
     <Header/>
     
    <br/>
    <br/>
    <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Paymentsuccespage;
