// Header.js
import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import { Link, useLocation } from 'react-router-dom';


function Footer() {

const MySwal = withReactContent(Swal)
const location = useLocation();

  return (
    <div>
      <div className="footerz">
      <Link to="/home" className={`footerz-button ${location.pathname === '/home' ? 'actively' : ''}`}>
        <i className='pe pe-7s-home' style={{ fontSize: '30px', width: '100px' }}></i>Home
      </Link>
      <Link to="/bookings" className={`footerz-button ${location.pathname === '/bookings' ? 'actively' : ''}`}>
        <i className='pe pe-7s-file' style={{ fontSize: '30px', width: '100px' }}></i>Bookings
      </Link>
      <Link to="/settings" className={`footerz-button ${location.pathname === '/settings' ? 'actively' : ''}`}>
        <i className='pe pe-7s-settings' style={{ fontSize: '30px', width: '100px' }}></i>Settings
      </Link>
      
    </div>
    
  </div>
  );
}

export default Footer;
