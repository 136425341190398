import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'

function Auth(){
  const MySwal = withReactContent(Swal)
  // const baseUrl = "http://localhost:9650/api/verify";
  const baseUrl = "http://localhost:9650/api/verify";

    const [state , setState] = useState({
        code: ''
    })


    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

      const urlParams = new URLSearchParams(window.location.search);
    const myidd = urlParams.get('myidd');
    console.log(myidd); // Make sure the value is retrieved correctl



      var postData = {
        "id": myidd,
        "code": state.code,
    };

    
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
 
      
      const handleLoginSubmit = (e) => {
        if (!state.code){
          MySwal.fire({
            title: <p>Please enter Auth Code</p>,
            didOpen: () => {
            },
          }).then(() => {
          })
        }else{
          MySwal.fire({
            title: <p>please wait...</p>,
            didOpen: () => {
              MySwal.showLoading()
              axios.post(baseUrl, postData, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              console.log(JSON.stringify(response.data.user.firstname))
                Swal.close()
                Swal.fire({
                    title: response.data.data,
                    text: response.data.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonText: "Okay",
                    closeOnCancel: true,
                  }).then(result => {
                    localStorage.setItem("ccing_tokenize", response.data.accessToken);
                    localStorage.setItem("ccing_fname", response.data.user.firstname);
                    localStorage.setItem("ccing_fi", response.data.user.id);
                    // localStorage.setItem("ccing_address", response.data.user.address);
                    localStorage.setItem("ccing_region", response.data.user.region);
                    localStorage.setItem("ccing_role", response.data.user.user_role);
                    localStorage.setItem("ccing_email", response.data.user.email);
                    localStorage.setItem("ccing_phone", response.data.user.phone);

                    window.location.href="home";
                // window.location.href="signin";
                  })
              })
              .catch((err) => {
                Swal.close()
                console.log("AXIOS ERROR: ", err);
                return MySwal.fire(err.response.data.message)
              })
            },
          }).then(() => {
          })
        }
      }



      return (
        <div>
        <div className="auth-main">
               <div className="auth-wrapper v1">
                 <div className="auth-form">
                   <div className="card my-1">
                     <div className="card-body">
                       <div className="text-center">
                         <a href="#"><img src="/assets/img/logo.png" width={'200'} alt="img" /></a>
                       </div>
                       <div className="saprator my-3">
                         <span>Enter code</span>
                       </div>
                       <div className="form-group mb-3">
                         <input type="text" className="form-control" value={state.code} onChange={handleChange} id="code" name="code" placeholder="Auth Code" />
                       </div>
                      
                       <div className="d-flex mt-1 justify-content-between align-items-center">
                         <div className="form-check">
                         </div>
                       </div>
                       <div className="d-grid mt-4">
                         <button type="button" onClick={handleLoginSubmit} className="btn btn-danger">Submit</button>
                       </div>
                       <div className="d-flex justify-content-between align-items-end mt-4">
                         {/* <h6 className="f-w-500 mb-0">Have an Account?</h6> */}
                         <a href="/register" className="link-primary">Go Back</a>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
            
                   </div>
               
        );
 
  };

export default Auth;
