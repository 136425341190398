import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content'
import Codes from './Codes';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Spinner from 'react-bootstrap/Spinner';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import dayjs from "dayjs";
import BASE_URL from './config';
import { Link, useLocation,useHistory } from 'react-router-dom';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";





function Newbooking(){
  const MySwal = withReactContent(Swal);
  const urlParams = new URLSearchParams(window.location.search);

  const calculateROIC = (netIncome, totalDebt, totalEquity) => {
    if (isNaN(netIncome) || isNaN(totalDebt) || isNaN(totalEquity)) {
      return 0;
    }
    const nopat = netIncome; 
  
    const investedCapital = totalEquity + totalDebt;
  
    if (investedCapital === 0) {
      return 0; 
    }
  
    return (nopat / investedCapital) * 100; 
  };
  

  const calculateEBITtoEV = (ebit, marketCap, totalDebt, cashAndEquivalents) => {
    // Ensure inputs are valid numbers
    if (isNaN(ebit) || isNaN(marketCap) || isNaN(totalDebt) || isNaN(cashAndEquivalents)) {
      return 0;
    }
  
    // Calculate Enterprise Value
    const enterpriseValue = marketCap + totalDebt - cashAndEquivalents;
  
    if (enterpriseValue === 0) {
      return 0; // Avoid division by zero
    }
  
    return ebit / enterpriseValue;
  };
  

  const calculateTrailingStdDev = (timeSeries) => {
    const closingPrices = Object.keys(timeSeries).map(date => parseFloat(timeSeries[date]["4. close"]));
  
    if (closingPrices.length < 100) {
      return null; // Not enough data
    }
  
    // Take the last 100 closing prices
    const last100Days = closingPrices.slice(0, 100);
    
    const mean = last100Days.reduce((acc, price) => acc + price, 0) / last100Days.length;
  
    const variance = last100Days.reduce((acc, price) => acc + Math.pow(price - mean, 2), 0) / last100Days.length;
  
    const stdDev = Math.sqrt(variance);
  
    return stdDev;
  };
  

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  const calculateMomentum = (timeSeries, startMonth, endMonth) => {
    // Extract the dates from the time series
    const dates = Object.keys(timeSeries).sort((a, b) => new Date(b) - new Date(a));
  
    // Calculate the index for the start and end of the momentum period
    const startIndex = startMonth * 21; // Assuming approximately 21 trading days per month
    const endIndex = endMonth * 21;
  
    if (dates.length < startIndex + 1) {
      return null; // Not enough data
    }
  
    const startDate = dates[startIndex];
    const endDate = dates[endIndex];
  
    const startPrice = parseFloat(timeSeries[startDate]["4. close"]);
    const endPrice = parseFloat(timeSeries[endDate]["4. close"]);
  
    if (startPrice === 0) {
      return null; // Avoid division by zero
    }
  
    const momentum = ((endPrice - startPrice) / startPrice) * 100; // Return as a percentage
  
    return momentum;
  };

  const checkSP500Trend = async (apiKey) => {
    try {
      // Fetch S&P 500 data (e.g., from Alpha Vantage)
      const response = await axios.get(`https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=SPY&apikey=VD6HDIULD4ED6LMA`);
      
      if (response.data && response.data["Time Series (Daily)"]) {
        const timeSeries = response.data["Time Series (Daily)"];
        const dates = Object.keys(timeSeries).sort((a, b) => new Date(b) - new Date(a));
        
        // Calculate the index for the start and end of the 6-month period
        const sixMonthsIndex = 6 * 21; // Assuming approximately 21 trading days per month
  
        if (dates.length < sixMonthsIndex + 1) {
          return false; // Not enough data to calculate
        }
  
        const recentDate = dates[0]; // Most recent date
        const sixMonthsAgoDate = dates[sixMonthsIndex]; // Date 6 months ago
  
        const recentClose = parseFloat(timeSeries[recentDate]["4. close"]);
        const sixMonthsAgoClose = parseFloat(timeSeries[sixMonthsAgoDate]["4. close"]);
  
        if (sixMonthsAgoClose === 0) {
          return false; // Avoid division by zero
        }
  
        const sixMonthReturn = (recentClose - sixMonthsAgoClose) / sixMonthsAgoClose;
  
        return sixMonthReturn > 0; // Returns true if the trend is positive
      } else {
        console.error("No data received or unexpected data format");
        return false;
      }
    } catch (error) {
      console.error("Error fetching S&P 500 data: ", error);
      return false;
    }
  };

  const apiKey = 'VD6HDIULD4ED6LMA';
    checkSP500Trend(apiKey).then(isPositive => {
      if (isPositive) {
        console.log("The S&P 500 has a positive trend. You can take new positions.");
      } else {
        console.log("The S&P 500 does not have a positive trend. Hold off on new positions.");
      }
    });

  

  const handleselectequity = () => {
    if (!state.codes.length) {
      Swal.fire({
        title: "Error",
        text: "Please select at least one equity",
        showCancelButton: true,
        closeOnCancel: true,
        cancelButtonText: "Cancel"
      });
    } else {
      MySwal.fire({
        didOpen: () => {
          MySwal.showLoading();
  
          const apiRequests = state.codes.map(code =>
            axios.get(`https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${code}&apikey=VD6HDIULD4ED6LMA`)
          );
  
          Promise.all(apiRequests)
            .then(responses => {
              const rawData = {};
              console.log(responses)
              responses.forEach((response, index) => {
                if (response.data && response.data["Time Series (Daily)"]) {
                  rawData[state.codes[index]] = response.data["Time Series (Daily)"];
                }
              });
              Swal.close();
              setState(prevState => ({
                ...prevState,
                rawData
              }));
            })
            .catch((error) => {
              console.error("Error fetching data: ", error);
              Swal.close();
            });
        },
      });
    }
  };

  const applyQualityValueVolatilityFilter = () => {
    const filteredData = Object.keys(state.rawData).map(code => {
      const timeSeries = state.rawData[code];
  
      // Example: Calculate metrics (these are placeholders; you'll need actual calculations)
      const roic = calculateROIC(timeSeries); // Implement this
      const ebitToEv = calculateEBITtoEV(timeSeries); // Implement this
      const stdDev = calculateTrailingStdDev(timeSeries); // Implement this
      console.log(roic);
      console.log(ebitToEv);
      console.log(stdDev);
  
      return {
        code,
        roic,
        ebitToEv,
        stdDev,
        weightedScore: (roic + ebitToEv + (1 / stdDev)) / 3
      };
    });
  
    const sortedData = filteredData.sort((a, b) => b.weightedScore - a.weightedScore);
    setState(prevState => ({
      ...prevState,
      filteredData: sortedData.slice(0, 50) // Keep top 50 stocks
    }));
  };
 
  const applyMomentumFilter = () => {
    const filteredData = state.filteredData.map(stock => {
      const timeSeries = state.rawData[stock.code];
      
      // Calculate 6-month return, skipping the last month
      const momentumScore = calculateMomentum(timeSeries); // Implement this
      console.log(momentumScore)
      return {
        ...stock,
        momentumScore
      };
    });
   
  
    const sortedData = filteredData.sort((a, b) => b.momentumScore - a.momentumScore);
    setState(prevState => ({
      ...prevState,
      filteredData: sortedData.slice(0, 20) // Keep top 20 based on momentum
    }));
  };

  const applyTrendRegimeFilter = () => {
    axios.get(`https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=SPY&apikey=VD6HDIULD4ED6LMA`)
      .then(response => {
        if (response.data && response.data["Time Series (Daily)"]) {
          const sp500Data = response.data["Time Series (Daily)"];
          const isSp500Positive = checkSP500Trend(sp500Data); // Implement this
  
          if (isSp500Positive) {
            // S&P 500 is positive, no further filtering needed
            return;
          } else {
            // Clear the filtered data since the market condition isn't favorable
            setState(prevState => ({
              ...prevState,
              filteredData: []
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching S&P 500 data: ", error);
      });
  };

  
  
  
  
  

  const handleseflectequity = (e) => {
    if (!state.codes || state.codes.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please select at least one equity",
        showCancelButton: true,
        closeOnCancel: true,
        cancelButtonText: "Cancel"
      });
    } else {
      MySwal.fire({
        didOpen: () => {
          MySwal.showLoading();
  
          // Array to store promises for all API requests
          const apiRequests = state.codes.map(code =>
            axios.get(`https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${code}&apikey=VD6HDIULD4ED6LMA`)
          );
  
          // Execute all API requests simultaneously
          Promise.all(apiRequests)
            .then(responses => {
              // Aggregate the data from each response
              const aggregatedData = [];
  
              responses.forEach((response, index) => {
                if (response.data && response.data["Time Series (Daily)"]) {
                  const timeSeries = response.data["Time Series (Daily)"];
                  const equitySymbol = state.codes[index]; // Get the symbol for the current response
  
                  Object.keys(timeSeries).forEach(date => {
                    aggregatedData.push({
                      date,
                      equity: equitySymbol,
                      opening: timeSeries[date]["1. open"],
                      highest: timeSeries[date]["2. high"],
                      lowest: timeSeries[date]["3. low"],
                      closing: timeSeries[date]["4. close"],
                      volume: timeSeries[date]["5. volume"],
                    });
                  });
                } else {
                  console.log(`No data received for symbol: ${state.codes[index]}`);
                }
              });
  
              // Update the state with the aggregated data
              setState(prevState => ({
                ...prevState,
                tableData: aggregatedData
              }));
            })
            .catch((error) => {
              console.error("Error fetching data: ", error);
            });
        },
      });
    }
  };
  

  const [state, setState] = useState({
    codes: [],
    rawData: {},
    filteredData: [],
    sp500Data: null,
  });


const handleChange2 = (name) => (selectedOptions) => {
  const selectedCodes = selectedOptions ? selectedOptions.map(option => option.value) : [];
  setState(prevState => ({
    ...prevState,
    [name]: selectedCodes
  }));
};
 
const columns = [
  { name: 'Code', selector: row => row.code, sortable: true },
  { name: 'Weighted Score', selector: row => row.weightedScore, sortable: true },
  // Add more columns as needed
];

  const data = state.tableData || [];
  const tableData = {
    columns,
    data
  };
  

      return (
     <div>
     <div className="pc-container">
        <div className="pc-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="page-header-title">
                    <h2 className="mb-0">Finance backtest</h2>
                    <br/>
                 <p>Please select from the equities below.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0 pt-2">
                  <ul className="nav nav-tabs analytics-tab" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab" data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane" aria-selected="true">Backtest Detail</button>
                    </li>
                   </ul>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-10 col-9">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Select equity</label>
                      <br/>
                      <br/>
                      <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={state.codes.map(code => ({ value: code, label: code }))}
                      isMulti
                      onChange={(selectedOptions) => {
                        const selectedCodes = selectedOptions ? selectedOptions.map(option => option.value) : [];
                        setState(prevState => ({
                          ...prevState,
                          codes: selectedCodes
                        }));
                      }}
                      options={Codes}
                    />
                    </div>
                    </div>
                  </div>
                  <button type="submit" onClick={handleselectequity} className='btn btn-primary btn-sm'>Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="submit" onClick={applyQualityValueVolatilityFilter} className='btn btn-primary btn-sm'>applyQualityValueVolatilityFilter</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="submit" onClick={applyMomentumFilter} className='btn btn-primary btn-sm'>applymomentumFilter</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="submit" onClick={applyTrendRegimeFilter} className='btn btn-primary btn-sm'>applyTrendRegimeFilter</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                  
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0 pt-2">
                  <ul className="nav nav-tabs analytics-tab" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab" data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane" aria-selected="true">Address Detail</button>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="row">
                  <div className="table-responsive mb-4 mt-4">
                
                <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
            
                <DataTable
                  columns={columns}
                  data={state.filteredData}
                  noHeader
                  defaultSortField="weightedScore"
                  pagination
                  highlightOnHover
                />
                </table>
              </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div>
           
           </div>
          </div>
          <br/>
           <br/>
           <br/>
           <br/>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
       </div>
               
        );
 
  };

export default Newbooking;
