import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import dayjs from "dayjs";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import withReactContent from 'sweetalert2-react-content'




function Bookings(){
  const location = useLocation();
  const [isHidden, setIsHidden] = useState(true);
  const history = useHistory();


  const mybookingaddress = localStorage.getItem('ccing_address')
  const myregion = localStorage.getItem('ccing_region')
  const [state , setState] = useState({
    trip: 'Round Trip',
    address: mybookingaddress,
    region: myregion,
})

const gotoupdate = (e) =>{
  const myidd = e.target.id;
  console.log(myidd)
  history.push(`/updatebooking?myidd=${myidd}`);
}

  const toggleDivs = () => {
    setIsHidden(!isHidden);
  };

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

const MySwal = withReactContent(Swal)
const [cust_info, setcust] = useState([]);
// const baseUrl = "http://localhost:9650/api/fetchbookingByuserId";
const baseUrl = "http://localhost:9650/api/fetchbookingByuserId";
const token = localStorage.getItem('ccing_tokenize')
const myidd = localStorage.getItem('ccing_fi')
let axiosConfig = {
headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
}
};
var body = {
      "accesstoken": token,
      "userID": myidd
      };
     
const getdashboardinfo = async (e) => {
      axios.post(baseUrl, body, axiosConfig)
      .then((response) => {
            console.log("response received: ", response);
            if(!response){
            setcust(0);
            }else{
            const sortedArray = response.data.data;
            const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
            setcust(numDescending);
            setcust(numDescending);
            setcust(numDescending);
            console.log(cust_info)
            }
      });
      };


      useEffect(() => {
      getdashboardinfo();
      // getitemsinfo();
      }, []);
 


      return (
     <div>
     <Header/>
     <div className="pc-container">
        <div className="pc-content">
          {/* [ breadcrumb ] start */}
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="page-header-title">
                    <h2 className="mb-0">Bookings</h2>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0 pt-2">
                  <ul className="nav nav-tabs analytics-tab" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab" data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane" aria-selected="true">Most Recent Bookings</button>
                    </li>
                   
                  </ul>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-5 col-xl-4">
                      <ul className="list-group list-group-flush">
                      {cust_info.map((customer, index) => (
                        <li className="list-group-item">
                          <div className="">
                            <div className="">
                              <div className="row g-1">
                                <div className="col-12">
                                  <p className="text-muted mb-1"><b>Booking Date:</b> {dayjs(customer.bookingdate).format('MMM D YYYY')}</p>
                                  <p className="text-muted mb-1"><b>Region:</b> {customer.bookingregion}</p>
                                  <p className="text-muted mb-1"><b>Address:</b> {customer.bookingaddress}</p>
                                  <p className="text-muted mb-1"><b>Amont Paid:</b> {customer.price}</p>
                                  <p className="text-muted mb-1"><b>Trip:</b> {customer.trip}</p>
                                  <p className="text-muted mb-1"><b>Status:</b> {customer.is_scheduled==1? 'Completed': 'Not completed'}</p>
                                  <p className="text-muted text-right mb-1"><b></b> {customer.is_scheduled==1? '': <a style={{color: 'red', fontWeight:'bold'}} onClick={gotoupdate} id={customer.id} className="text-green">Update</a>}</p>
                                </div>
                                <div className="col-4 text-end">
                                {/* <button className="btn btn-outline-danger">Edit</button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            {/* <div className="d-grid">
                        <button className="btn btn-outline-primary">Submit</button>
                </div> */}
          </div>
          <br/>
           <br/>
           <br/>
           <br/>
          {/* [ Main Content ] end */}
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/><div className="footerz">
      <Link to="/home" className='footerz-button'>
        <i className='pe pe-7s-home' style={{ fontSize: '30px', width: '100px' }}></i>Home
      </Link>
      <Link to="/bookings" className='footerz-button actively'>
        <i className='pe pe-7s-file' style={{ fontSize: '30px', width: '100px' }}></i>Bookings
      </Link>
      <Link to="/settings" className='footerz-button'>
        <i className='pe pe-7s-settings' style={{ fontSize: '30px', width: '100px' }}></i>Settings
      </Link>
    </div>
              </div>
               
        );
 
  };

export default Bookings;
