import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import storage from "./firebaseConfig.js"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";

function Updateprofile(){
    const MySwal = withReactContent(Swal)
    const token = localStorage.getItem('ccing_tokenize')
    const mypassword = localStorage.getItem('ccing_password')
    const userid = localStorage.getItem('ccing_fi')
    const passwordURL = "http://localhost:9650/api/updatepassword";

    const [state, setState] = useState({
      new: '',
      current: '',
      confirm: ''
    });

    const history = useHistory();
    const goback = () => {
      history.push('/settings'); // Replace '/settings' with the actual path of your settings page
    };

    var body = {
      "accesstoken": token,
      "userid": userid,
      "newPassword": state.new
      };


    
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };

    
      const handleupdateuser = async (e) => {
        e.preventDefault()
        if (!state.new|| !state.confirm||!state.current){
          MySwal.fire({
            title: <p>Please fill all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {})
        }else if(state.current != mypassword){
          MySwal.fire({
            title: <p>Incorrect Password</p>,
            didOpen: () => {
            },
          }).then(() => {})
         }else if(state.new != state.confirm){
          MySwal.fire({
            title: <p>Password and Password confirmation must match</p>,
            didOpen: () => {
            },
          }).then(() => {})
        }else{
            MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading()
            axios.post(passwordURL, body, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
              Swal.close()
              Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  closeOnCancel: true,
                }).then(result => {
                  console.log(response);
                  localStorage.setItem("ccing_address", state.address);
                    localStorage.setItem("ccing_zipcode", state.zipcode);
                    localStorage.setItem("ccing_region", state.region);
                    localStorage.setItem("ccing_password", state.new);
                    window.location.href="changepassword";
                })
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data)
            })
          },
        }).then(() => {
        })
        }
      }

      return (
              <div>
     <Header/>
     <div className="pc-container">
        <div className="pc-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <ul className="breadcrumb mb-3">
                  <li className="breadcrumb-item"><i onClick={goback} className='pe pe-7s-angle-left' style={{ fontSize: '50px', width: '100px' }}></i></li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className="page-header-title">
                    <h2 className="mb-0">Change Password</h2>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            
            <div className="col-12">
            <div className="row g-1">
            <div className="col-12">
                  <label>Current Password</label>
                  <input type='password'  placeholder='Current password' value={state.current} onChange={handleChange} id="current" name="current" className='form-control'/>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>

                <div className="col-12">
                  <label>New Password</label>
                  <input type='password'  placeholder='New password' value={state.new} onChange={handleChange} id="new" name="new" className='form-control'/>
                </div>
            
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="col-12">
                  <label>Confirm Password</label>
                  <input type='password' placeholder='Confirm password' value={state.confirm} onChange={handleChange} id="confirm" name="confirm" className='form-control'/>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
          <br/>
            <div>
               <center> <button style={{width: '100%'}} onClick={handleupdateuser} className='btn btn-lg btn-success btn-block'>Change Password</button></center>
            </div>
          <br/>
           <br/>
           <br/>
           <br/>
          {/* [ Main Content ] end */}
        </div>
      </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default Updateprofile;
