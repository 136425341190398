import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import "react-data-table-component-extensions/dist/index.css";


function ProfileSetup(){
      const history = useHistory();
      const personalinfo = (e) => {
        history.push('/updateprofile'); 
      }

      // const viewnotifications = (e) => {
      //   console.log('i dey here')
      //   window.location.href='notifications';
      // }

      const changepassword = (e) => {
        history.push('/changepassword');
      }

      const logout = (e) => {
        Swal.fire({
          title: 'Logout',
          text: 'Are you sure you want to logout?',
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnCancel: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/';
            localStorage.removeItem('ccing_fi');
            localStorage.removeItem('ccing_tokenize');
            history.replace('/');
          }
        });
       
      }

      const gopassword = () => {
        
      }
     

    
      return (
              <div>
     <Header/>
     <div className="pc-container">
        <div className="pc-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-12">
                </div>
                <div className="col-md-12">
                  <div className="page-header-title">
                    <h2 className="mb-0">Settings</h2>
                    <br/>
                    {/* <p>Update profile information here</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
          {/* <div className="card bg-warning available-balance-card" onClick={viewnotifications}>
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                <b>  <p className="mb-0 text-white text-white">View Notifications</p></b>
                </div>
                <div className="avtar">
                  <i className="fa fa-bell f-18" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="card bg-primary available-balance-card" onClick={personalinfo}>
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                <b>  <p className="mb-0 text-white text-white">Update Personal Information</p></b>
                </div>
                <div className="avtar">
                  <i className="fa fa-user f-18" />
                </div>
              </div>
            </div>
          </div>

          <div className="card bg-success available-balance-card" onClick={changepassword}>
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                <b>  <p className="mb-0 text-white text-white">Change User Password</p></b>
                </div>
                <div className="avtar">
                  <i className="fa fa-unlock f-18" />
                </div>
              </div>
            </div>
          </div>

          <div className="card bg-danger available-balance-card" onClick={logout}>
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                <b>  <p className="mb-0 text-white text-white">Logout</p></b>
                </div>
                <div className="avtar">
                  <i className="fa fa-sign-out f-18" />
                </div>
              </div>
            </div>
          </div>
          </div>
          <br/>
          
          <br/>
           <br/>
           <br/>
           <br/>
          {/* [ Main Content ] end */}
        </div>
      </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default ProfileSetup;
