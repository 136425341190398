import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

function ProfileSetup(){
    const MySwal = withReactContent(Swal)
    const [reg_info, setreg] = useState([]);
    const [latitudeinfo, setLatitude] = useState();
    const [longitudeinfo, setLongitude] = useState();
    const [addressinfo, setAddress] = useState();
    

    const [itemsfetched, setItemsfetched] = useState([]);
    const baseUrl = "http://localhost:9650/api/updateUser";
    // const baseUrl = "http://localhost:9650/api/updateUser";
    const regionUrl = "http://localhost:9650/api/fetchregions";
    // const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/createItem";
    const token = localStorage.getItem('ccing_tokenize')
    const myidd = localStorage.getItem('ccing_fi')

    const [state, setState] = useState({
      region: localStorage.getItem('ccing_region') || '',
      address: localStorage.getItem('ccing_address') || '',
      zipcode: localStorage.getItem('ccing_zipcode') || '',
    });

    const history = useHistory();
    const goback = () => {
      history.push('/settings'); // Replace '/settings' with the actual path of your settings page
    };

    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      callbackName: "initMap",
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 300,
    });

    const ref = useOnclickOutside(() => {
      clearSuggestions();
    });

    const handleSelect =
      ({ description }) =>
      () => {
        setValue(description, false);
        clearSuggestions();
        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          console.log("📍 Coordinates: ", { lat, lng });
          setLatitude(lat);
          setLatitude(lat);
          setLatitude(lat);
          setLongitude(lng);
          setLongitude(lng);
          setLongitude(lng);
          setAddress(description)
          setAddress(description)
          setAddress(description)
        });
      };

      const renderSuggestions = () =>
        data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <li key={place_id} onClick={handleSelect(suggestion)}>
              <strong>{main_text}</strong> <small>{secondary_text}</small>
            </li>
          );
        });

    
    const handleInput = (e) => {
      setValue(e.target.value);
    };
  
    

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };

      var body = {
        "accesstoken": token,
        };
       
  const getregioninfo = async (e) => {
        axios.post(regionUrl, body, axiosConfig)
        .then((response) => {
              console.log("response received: ", response);
              if(!response){
                setreg(0);
              }else{
              const sortedArray = response.data.data;
              setreg(sortedArray);
              setreg(sortedArray);
              setreg(sortedArray);
              setreg(sortedArray);
              console.log(reg_info)
              }
        });
        };
  
  
        useEffect(() => {
        getregioninfo();
                  // getitemsinfo();
        }, []);

      const handleupdateuser = async (e) => {
        e.preventDefault()
        if (!state.region || !addressinfo|| !state.zipcode){
          MySwal.fire({
            title: <p>Please fill all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {})
        }else{
          var postData = {
            "region": state.region,
            "id": myidd,
            "address": addressinfo,
            "latitude": latitudeinfo,
            "longitude": longitudeinfo,
            "zipcode": state.zipcode,
            "accesstoken": token,
        };
          console.log(postData)
          MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading()
            axios.post(baseUrl, postData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
              Swal.close()
              Swal.fire({
                  text: response.data.success,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  closeOnCancel: true,
                }).then(result => {
                  console.log(response);
                    localStorage.setItem("ccing_address", state.address);
                    localStorage.setItem("ccing_zipcode", state.zipcode);
                    localStorage.setItem("ccing_region", state.region);
                    localStorage.setItem("ccing_latitude", latitudeinfo);
                    localStorage.setItem("ccing_longitude", longitudeinfo);
                    window.location.href="home";
                    console.log(state.region);
                })
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data.message)
            })
          },
        }).then(() => {
        })
        }
      }

      return (
              <div>
     <Header/>
     <div className="pc-container">
        <div className="pc-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <ul className="breadcrumb mb-3">
                  {/* <li className="breadcrumb-item"><i onClick={goback} className='pe pe-7s-angle-left' style={{ fontSize: '50px', width: '100px' }}></i></li> */}
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className="page-header-title">
                    <h2 className="mb-0">Update Profile</h2>
                    <br/>
                    <p>Please update address and region below</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            <div className="col-12">
            <div className="row g-1">
                <div className="col-12">
                  <label>Address</label>
                  <input
                  value={value}
                  onChange={handleInput}
                  className='form-control'
                  disabled={!ready}
                  placeholder="Enter Complete address"
                />
                {status === "OK" && <ul className='card'>{renderSuggestions()}</ul>}
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="col-12">
                  <label>Postal Code</label>
                  <input type='text' placeholder='Enter postal code' value={state.zipcode} onChange={handleChange} id="zipcode" name="zipcode" className='form-control'/>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="col-12">
                  <label>Region</label>
                  
                  <select className="form-control" value={state.region} onChange={handleChange} id="region" name="region">
                  <option>--select--</option>
                  {reg_info.map(info => (
                                              <option key={info.id} value={info.region_name}>
                                                {info.region_name}
                                              </option>
                                            ))}
                         </select>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/><br/>
          </div>
          <br/>
            <div>
               <center> <button onClick={handleupdateuser} className='btn btn-lg btn-primary btn-block'>Submit</button></center>
            </div>
          <br/>
           <br/>
           <br/>
           <br/>
          {/* [ Main Content ] end */}
        </div>
      </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default ProfileSetup;
