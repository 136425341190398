
import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import {useHistory } from 'react-router-dom';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from 'axios';



function Verifyauth(){
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const [state , setState] = useState({
    confirmpassword: '',
    loginpassword: '',
    otp: '',
  })
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const logingo = () => {
    history.push('/')
  }
  
  const loginUrl = "http://localhost:9650/api/reset_password";
//   const loginUrl = "http://localhost:9650/api/reset_password";
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }
  var postloginData = {
    "otp": state.otp,
    "password": state.loginpassword,
  };
  
  let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    };

    const handleLoginSubmit = (e) => {
        e.preventDefault()
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isValidEmail = (email) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        };
        if (!state.otp||!state.loginpassword){
          MySwal.fire({
            title: <p>Please fill all fields</p>,
            didOpen: () => {
            },
          }).then(() => {
          })
        }else if( state.loginpassword != state.confirmpassword){
            MySwal.fire({
                title: <p>Password and confirm password must match</p>,
                didOpen: () => {},
              }).then(() => {});

        }else if(!passwordRegex.test(state.loginpassword)) {
            MySwal.fire({
                title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
                didOpen: () => {},
              }).then(() => {});
        
        }else{
          MySwal.fire({
            title: <p>please wait...</p>,
            didOpen: () => {
              MySwal.showLoading()
              axios.post(loginUrl, postloginData, axiosConfig)
              .then((response) => {
                console.log("response received: ", response);
                Swal.close();
                MySwal.fire({
                  text: "Password reset successful", // Use text property to display message
                }).then(() => {
                  history.push('/');
                });
              })
              .catch((err) => {
                Swal.close()
                console.log("AXIOS ERROR: ", err);
                if (err.response.data.message){
                  return MySwal.fire(err.response.data.message)
                } else {
                  return MySwal.fire(err.response.message)
                }
              })
            },
          }).then(() => {
          })
        }
      }
  
     
    
    


      return (
              <div>
   <div className="auth-main">
          <div className="auth-wrapper v1">
            <div className="auth-form">
              <div className="card my-1">
                <div className="card-body">
                  <div className="text-center">
                    <a href="#"><img src="/assets/img/logo.png" width={'200'} alt="img" /></a>
                  </div>
                  <div className="saprator my-3">
                    <span>Enter Details</span>
                  </div>
                  {/* <h4 className="text-center f-w-500 mb-3">Login with your email</h4> */}
                  <div className="form-group mb-3">
                    <input type="email" className="form-control" value={state.otp} onChange={handleChange} id="otp" name="otp" placeholder="Enter OTP" />
                  </div>
                  <div className='input-group'>
                    <input type={showPassword ? 'text' : 'password'} placeholder="New Password" value={state.loginpassword} onChange={handleChange} id="loginpassword" name="loginpassword" className="form-control bg-transparent" /> 
                      <span class="input-group-text" id="basic-addon2">
                          <i class="fa fa-eye" onClick={togglePasswordVisibility}></i>
                      </span>
                  </div>
                    <br/>
                  <div className='input-group'>
                    <input type={showPassword ? 'text' : 'password'} placeholder="Confirm Password" value={state.confirmpassword} onChange={handleChange} id="confirmpassword" name="confirmpassword" className="form-control bg-transparent" /> 
                      <span class="input-group-text" id="basic-addon2">
                          <i class="fa fa-eye" onClick={togglePasswordVisibility}></i>
                      </span>
                  </div>
                  <div className="d-grid mt-4">
                    <button type="button" onClick={handleLoginSubmit} className="btn btn-danger">Submit</button>
                  </div>
                  <div className="d-flex justify-content-between align-items-end mt-4">
                    {/* <h6 className="f-w-500 mb-0">Don't have an Account?</h6> */}
                    <a onClick={logingo} className="link-primary">Back to login</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
              </div>
               
        );
 
  };

export default Verifyauth;
