import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Signin from './component/signin';
import Profile from './component/profile';
import ProfileSetup from './component/profilesetup';
import Home from './component/home';
import Auth from './component/authorize';
import Notifications from './component/notifications';
import Register from './component/register';
import Changepassword from './component/changepassword';
import Updateprofile from './component/updateprofile';
import Bookings from './component/bookings';
import Newbooking from './component/newbooking';
import Updatebooking from './component/updatebooking';
import Settings from './component/settings';
import Paymentsuccespage from './component/paymentsuccespage';
import Errorpage from './component/errorpage';
import forgotpassword from './component/forgotpassword';
import Verifyauth from './component/verifyauth';


const stripePromise = loadStripe('pk_test_51JDvPcExCELSjEQdzbBRXkXzRIspi0jRO7qU0vwOjEAghJbACPv91shKsDdi7H2uLA9kWwpLV83qPKQWrSFuAyCV00fvcWHl5L');






export default function BasicExample() {
  return (
    <Elements stripe={stripePromise}>
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Newbooking}/>
          <Route exact path="/profile" component={Profile}/>
          <Route exact path="/updateprofile" component={Updateprofile}/>
          <Route exact path="/verifyauth" component={Verifyauth}/>
          <Route exact path="/updatebooking" component={Updatebooking}/>
          <Route exact path="/Forgotpassword" component={forgotpassword}/>
          <Route exact path="/paymentsuccespage" component={Paymentsuccespage}/>
          <Route exact path="/errorpage" component={Errorpage}/>
          <Route exact path="/changepassword" component={Changepassword}/>
          <Route exact path="/profileSetup" component={ProfileSetup}/>
          <Route exact path="/auth" component={Auth}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/notifications" component={Notifications}/>
          <Route exact path="/bookings" component={Bookings}/>
          <Route exact path="/newbooking" component={Newbooking}/>
          <Route exact path="/settings" component={Settings}/>
          <Route exact path="/register" component={Register}/>
          
        </Switch>
      </div>
    </Router>
    </Elements>

  );
}



