import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import dayjs from "dayjs";
import { Link, useLocation, useHistory } from 'react-router-dom';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";


function Updatebooking(){
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const baseUrl = "http://localhost:9650/api/fetchbookingById";
  const regionUrl = "http://localhost:9650/api/fetchregions";
  const bookingURL = "http://localhost:9650/api/updatebookingbyId";
  // const bookingURL = "http://localhost:9650/api/updatebookingbyId";
  
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myidd');
  const [reg_info, setreg] = useState([]);
  const [booking_info, setbooking] = useState([]);
  const mybookingaddress = localStorage.getItem('ccing_address');
  const myregion = localStorage.getItem('ccing_region');
  const token = localStorage.getItem('ccing_tokenize');
  const history = useHistory();
  const goback = () => {
    history.push('/bookings'); // Replace '/settings' with the actual path of your settings page
  };
  const nextSunday = dayjs().day(0).add(7, 'days').startOf('day');
  const [selectedSunday, setSelectedSunday] = useState('');
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSunday(selectedValue);
  };



  const [sundays, setSundays] = useState([]);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const getCurrentMonthAndYear = () => {
    const today = new Date();
    const mycurrentDay = today.getDate(); // Use getDate() instead of getdate()
    const mycurrentMonthIndex = today.getMonth();
    const mycurrentMonth = monthNames[mycurrentMonthIndex]; // Months are zero-based, so add 1
    const mycurrentYear = today.getFullYear();
  
    return { mycurrentMonth, mycurrentYear, mycurrentDay };
  };

  const getNextSundays = () => {
    const today = new Date();
    const currentDayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const daysUntilNextSunday = 7 - currentDayOfWeek; // Calculate days until next Sunday
  
    const nextSundays = [];
    for (let i = 0; i < 5; i++) {
      const nextSunday = new Date(today);
      nextSunday.setDate(today.getDate() + daysUntilNextSunday + i * 7);
      nextSundays.push(nextSunday);
    }
  
    return nextSundays;
  };
  
  const { mycurrentMonth, mycurrentYear, mycurrentDay } = getCurrentMonthAndYear();
  const nextSundays = getNextSundays();

  const handleSundayChange = (event) => {
    setSelectedSunday(event.target.value);
  };

  


  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  const getbookingsbody = {
    'bookingId': myidd,
    "accesstoken": token
  }

  const getdashboardinfo = async () => {
    try {
      const response = await axios.post(baseUrl, getbookingsbody, axiosConfig);
      console.log("response received: ", response);
  
      if (!response.data || !response.data.data || response.data.data.length === 0) {
        setbooking(0);
      } else {
        const sortedArray = response.data.data[0];
        setbooking(sortedArray);
        console.log(sortedArray);
        setState((prevState) => ({
            ...prevState,
            bookingaddress: sortedArray.bookingaddress || '',
          }));
      }
    } catch (error) {
      console.error("Error fetching dashboard info:", error);
    }
  };

  var body = {
    "accesstoken": token,
    };
   
const getregioninfo = async (e) => {
    axios.post(regionUrl, body, axiosConfig)
    .then((response) => {
          // console.log("response received: ", response);
          if(!response){
            setreg(0);
          }else{
          const sortedArray = response.data.data;
          setreg(sortedArray);
          setreg(sortedArray);
          setreg(sortedArray);
          setreg(sortedArray);
          // console.log(sortedArray)
          }
    });
    };

    

    useEffect(() => {
      const getNextSundays = () => {
        const today = new Date();
        const nextSundays = [];
  
        for (let i = 0; i < 5; i++) {
          const nextSunday = new Date(today);
          nextSunday.setDate(today.getDate() + ((0 - today.getDay() + 7) % 7) + i * 7);
  
          // Exclude the current Sunday
          if (i === 0 && nextSunday.toDateString() === today.toDateString()) {
            continue;
          }
  
          nextSundays.push(nextSunday);
        }
  
        return nextSundays;
      };
  
        setSundays(getNextSundays());

    getdashboardinfo();
    getregioninfo()
    }, []);

    const handleBookingSelect = (bookingId) => {
        const bookingDetails = getdashboardinfo();
      
        if (bookingDetails) {
          setState((prevState) => ({
            ...prevState,
            bookingRegion: booking_info.bookingregion,
          }));
        }
      };

    const [state , setState] = useState({
        bookingaddress: '' ,
        region: ''
    })

            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
              };

              const formattedDate = dayjs(selectedSunday).format('MMM D YYYY');
              const jsDate = new Date(formattedDate);
              const year = jsDate.getFullYear();
              const month = (jsDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
              const day = jsDate.getDate().toString().padStart(2, '0');
              const mysqlDateFormat = `${year}-${month}-${day}`;

              
              const handleupdateuser = async (e) => {
                e.preventDefault()
                if (!selectedSunday){
                    console.log(state.region, state.bookingaddress)
                  MySwal.fire({
                    title: <p>Please fill all required fields</p>,
                    didOpen: () => {
                    },
                  }).then(() => {})
                }else{
                  var postData = {
                    "date": mysqlDateFormat,
                    "bookingId": myidd,
                    // "address": state.bookingaddress,
                    "accesstoken": token
                };
                  console.log(postData)
                    MySwal.fire({
                  title: <p>please wait...</p>,
                  didOpen: () => {
                  console.log(postData)

                    MySwal.showLoading()
                    axios.post(bookingURL, postData, axiosConfig)
                  .then((response) => {
                    console.log("response received: ", response);
                      Swal.close()
                      Swal.fire({
                          text: response.data.message,
                          icon: "success",
                          showCancelButton: false,
                          confirmButtonText: "Okay",
                          closeOnCancel: true,
                        }).then(result => {
                          console.log(response);
                            window.location.href="bookings";
                        })
                    })
                    .catch((err) => {
                      Swal.close()
                      console.log("AXIOS ERROR: ", err);
                      return MySwal.fire(err.response.data)
                    })
                  },
                }).then(() => {
                })
                }
              }


  const [isHidden, setIsHidden] = useState(true);
  const toggleDivs = () => {
    setIsHidden(!isHidden);
  };


  return (
    <div>
<Header/>
    <div className="pc-container">
    <div className="pc-content">
    <div className="page-header">
      <div className="page-block">
        <div className="row align-items-center">
          <div className="col-md-12">
            <ul className="breadcrumb mb-3">
            <li className="breadcrumb-item"><i onClick={goback} className='pe pe-7s-angle-left' style={{ fontSize: '50px', width: '100px' }}></i></li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="page-header-title">
              <h2 className="mb-0">Update Booking</h2>
              <br/>
              <p>Update Booking Dates here</p>
            </div>
            <div className={`nav-item ${isHidden ? '' : 'myhidden'}`}>
                    <a className='btn btn-success ' style={{color: 'white', float: 'right'}} onClick={toggleDivs}>Edit Booking Date</a>
                    </div>
                    <div className={`nav-item ${isHidden ? 'myhidden' : ''}`}>
                      <a className='btn btn-danger ' style={{color: 'white', float: 'right'}} onClick={toggleDivs}>Cancel</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            <div className={`nav-item ${isHidden ? '' : 'myhidden'}`}>
            <div className="row">
              <div className="col-12">
              <div className="row g-1">
              <div className="col-12">
                    <label>Pickup Date</label>
                    <input type='text' disabled placeholder='change email' value={dayjs(booking_info.bookingdate).format('MMM D YYYY')} onChange={handleChange} id="bookingdate" name="bookingdate" className='form-control'/>
                  </div>
              
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                    <label>Pickup Address</label>
                    <input type='text' placeholder='change address' disabled value={state.bookingaddress} onChange={handleChange} id="bookingaddress" name="bookingaddress" className='form-control'/>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                    <label>Trip</label>
                    <input type='text' disabled  value={booking_info.trip} onChange={handleChange} id="trip" name="trip" className='form-control'/>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                    <label>Amount ($)</label>
                    <input type='text' disabled  value={booking_info.price} onChange={handleChange} id="price" name="price" className='form-control'/>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <div className="col-12">
                  <input type='text' disabled  value={booking_info.bookingregion} onChange={handleChange} id="region" name="region" className='form-control'/>

                        {/* <label>Region</label>
                        <select
                          className="form-control"
                          value={state.region}
                          onChange={handleChange}
                          id="region"
                          name="region"
                        >
                          <option>--select--</option>
                          {reg_info.map((info) => (
                            <option key={info.id} value={info.region_name}>
                              {info.region_name}
                            </option>
                          ))}
                        </select> */}
                      </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/><br/>

              

            </div>
            </div>

            <div className={`nav-item ${isHidden ? 'myhidden' : ''}`}>
            <div className="row">
              <div className="col-12">
              <div className="row g-1">
              <div className="col-12">
                    <label>Pickup Date</label>
                    <select onChange={handleSelectChange} className='form-control' value={selectedSunday}>
                    <option value={''}>---</option>
                      {sundays.map((sunday, index) => (
                        <option key={index} value={sunday.toISOString()}>
                          {sunday.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
                        </option>
                      ))}
                    
                    </select>
                  </div>
              
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                    <label>Pickup Address</label>
                    <input type='text' placeholder='change address' disabled value={state.bookingaddress} onChange={handleChange} id="bookingaddress" name="bookingaddress" className='form-control'/>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                    <label>Trip</label>
                    <input type='text' disabled  value={booking_info.trip} onChange={handleChange} id="trip" name="trip" className='form-control'/>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                    <label>Amount</label>
                    <input type='text' disabled  value={booking_info.price} onChange={handleChange} id="price" name="price" className='form-control'/>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-12">
                        <label>Region</label>
                  <input type='text' disabled  value={booking_info.bookingregion} onChange={handleChange} id="region" name="region" className='form-control'/>

                            {/* <select className="form-control" value={state.region} disabled onChange={handleChange} id="region" name="region">
                              <option>--select--</option>
                              {reg_info.map(info => (
                                  <option key={info.id} value={info.region_name}>
                                    {info.region_name}
                                  </option>
                                ))}
                            </select>  */}
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/><br/>

              

            </div>
            <br/>
              <div>
                <center> <button style={{width: '100%'}} onClick={handleupdateuser} className='btn btn-lg btn-primary btn-block'>Update</button></center>
              </div>
            </div>


   
    <br/>
    <br/>
    <br/>
    <br/>
    {/* [ Main Content ] end */}
    </div>
    </div>
    <br />
    <br />
    <br />
    <Footer/>
        </div>
     
);
 
  };

export default Updatebooking;
