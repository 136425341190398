import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function About(){
      return (
     <div>
     <Header/>
    
     <div className="blog-grid pb-100px pt-100px main-blog-page single-blog-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="blog-posts">
                <div className="single-blog-post blog-grid-post">
                  <center><h2 className="blog-title">ABOUT US</h2></center>
                    <br/>
                    <br/>
                  <div className="blog-image single-blog" data-aos="fade-up" data-aos-delay={200}>
                    <center>
                    </center>
                  </div>
                  <div className="blog-post-content-inner mt-30px" data-aos="fade-up" data-aos-delay={400} style={{textAlign:'justify'}}>
                    <div className='row'>
                      <div className='col-lg-6'>
                      <img className="img-fluid h-auto" src="assets/images/homeicon.png" style={{width:"80%"}} alt="blog" />
                      </div>

                    <div className='col-lg-6'>
                     <div> <h4 className="blog-title">JUSTFITNESS WEAR</h4><span><h6>Elevating Your Active Lifestyle with Style and Comfort</h6></span></div>
                      <p data-aos="fade-up">
                    At Justgym Fitness Wear, we aren't just a brand; we're a lifestyle. We understand that staying active and 
                    maintaining a healthy lifestyle is essential, and we believe that your activewear should reflect your 
                    dedication while ensuring comfort, style, and performance. That's why we've emerged as the
                     best brand in activewear, catering to individuals who strive for excellence in both their 
                     fitness journey and their fashion choices.
                    </p>
                   
                      </div>
                    </div>
                    <p>
                    <h4>Unparalleled Quality</h4>
                    Our commitment to excellence begins with the materials we select. 
                    We source the finest, high-performance fabrics that offer optimal breathability, moisture-wicking,
                    and flexibility. Every stitch and seam in our activewear is a testament to our dedication to quality, 
                    ensuring that you can move freely and confidently, no matter the intensity of your workout.
                    </p>
                    <div className="single-post-content">
                    <p className="quate-speech" data-aos="fade-up" data-aos-delay={200}>
                    We use high-performance fabrics that offer breathability, moisture-
                    wicking, and flexibility.
                    </p>
                  </div>
                

                  <h4>Innovative Designs</h4>
                    <p>
                    Justgym Fitness Wear isn't just about functionality – it's about looking good while you sweat it out. 
                    Our design team seamlessly merges fashion and fitness to create activewear that doesn't compromise on style. 
                    Whether you're hitting the gym, practicing yoga, or going for a run, 
                    </p>

                    <div className='row'>
                    
                    <div className='col-lg-6'>
                      <p>our carefully crafted designs empower you to 
                    express your individuality while staying on top of the latest trends.</p>
                    <h4>Tailored Fit</h4>
                    <p>We understand that every body is unique. Our activewear is designed to flatter various body types and sizes, 
                      promoting confidence and comfort. The perfect fit isn't just about aesthetics – 
                      it's about optimizing your performance and allowing you to focus on what matters most: your workout.</p>
                      </div>
                      <div className='col-lg-6'>
                       <img className="img-fluid h-auto" src="assets/images/homeicon8.jpeg" style={{width:"100%"}} alt="blog" />
                      </div>
                      </div>
                      <h4>Versatility Redefined</h4>
                      <p>Justgym Fitness Wear isn't limited to the gym. Our activewear seamlessly transitions from your workout sessions
                         to your everyday activities, blurring the line between fitness and fashion.
                         With our pieces, you'll find yourself effortlessly moving from the studio to the streets without skipping a beat.</p>
                         <h4>Experience Justgym Fitness Wear</h4>
                         <p>Elevate your active lifestyle with activewear that redefines what it means to be stylish, 
                          comfortable, and performance-driven. Join the movement that's sweeping across fitness communities, 
                          and embrace a brand that's dedicated to your success – both in and out of the gym. 
                          Justgym Fitness Wear: Where fashion meets function in perfect harmony.</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default About;
