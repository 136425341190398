import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import dayjs from "dayjs";
import BASE_URL from './config';
import Spinner from 'react-bootstrap/Spinner';
import Header from './header'; 
import Footer from './footer';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import "react-data-table-component-extensions/dist/index.css";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";

function Home(){
    const MySwal = withReactContent(Swal)
    const baseUrl =  BASE_URL+'/createItem';

    // const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/createItem";
    const token = localStorage.getItem('ccing_tokenize');
    const nextSunday = dayjs().day(0).add(7, 'days').startOf('day');
    // const [selectedSunday, setSelectedSunday] = useState(nextSunday.toISOString());
    const [sundays, setSundays] = useState([]);
  const [selectedSunday, setSelectedSunday] = useState('');
    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedSunday(selectedValue);
    };
    const history = useHistory();



const [cust_info, setcust] = useState([]);
const bookingUrl =  BASE_URL+'/fetchbookingByuserId';
const myidd = localStorage.getItem('ccing_fi')
var body = {
      "accesstoken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDksImlhdCI6MTcxODE2MjgyNiwiZXhwIjoxNzE4MjQ5MjI2fQ.jvTEfzayAMe1J0gEr6DfASSDksdD7MG2JDfy0_LiWFA",
      "userID": 49
      };
     
const getdashboardinfo = async (e) => {
      axios.post(bookingUrl, body, axiosConfig)
      .then((response) => {
            console.log(body);
            console.log("response received: ", response);
            if(!response){
            setcust(2);
            }else{
            const sortedArray = response.data.data;
            setcust(sortedArray);
            setcust(sortedArray);
            setcust(sortedArray);
            setcust(sortedArray);
            console.log(cust_info)
            }
      });
      };


    // const [sundays, setSundays] = useState([]);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const getCurrentMonthAndYear = () => {
      const today = new Date();
      const mycurrentDay = today.getDate(); // Use getDate() instead of getdate()
      const mycurrentMonthIndex = today.getMonth();
      const mycurrentMonth = monthNames[mycurrentMonthIndex]; // Months are zero-based, so add 1
      const mycurrentYear = today.getFullYear();
    
      return { mycurrentMonth, mycurrentYear, mycurrentDay };
    };
    
    const getNextSundays = () => {
      const today = new Date();
      const currentDayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
      const daysUntilNextSunday = 7 - currentDayOfWeek; // Calculate days until next Sunday
    
      const nextSundays = [];
      for (let i = 0; i < 5; i++) {
        const nextSunday = new Date(today);
        nextSunday.setDate(today.getDate() + daysUntilNextSunday + i * 7);
        nextSundays.push(nextSunday);
      }
    
      return nextSundays;
    };
    
    const { mycurrentMonth, mycurrentYear, mycurrentDay } = getCurrentMonthAndYear();
    const nextSundays = getNextSundays();

    const handleSundayChange = (event) => {
      setSelectedSunday(event.target.value);
    };
    
 
    // const { mycurrentMonth, mycurrentYear, mycurrentDay } = getCurrentMonthAndYear();
    
    const reserveBooking = (e) => {
      if (!selectedSunday) {
        return MySwal.fire('Please select a Date').then(() => {
        });
      } else 
      if (!localStorage.getItem('ccing_address')) {
        return MySwal.fire('Please complete your profile').then(() => {
          history.push('/profilesetup');
        });
      }
    
      const time = e;
      history.push(`/newbooking?myidd=${time}&date=${selectedSunday}`);
      
    };


    useEffect(() => {
      const getNextSundays = () => {
        const today = new Date();
        const nextSundays = [];
  
        for (let i = 0; i < 5; i++) {
          const nextSunday = new Date(today);
          nextSunday.setDate(today.getDate() + ((0 - today.getDay() + 7) % 7) + i * 7);
  
          // Exclude the current Sunday
          if (i === 0 && nextSunday.toDateString() === today.toDateString()) {
            continue;
          }
  
          nextSundays.push(nextSunday);
        }
  
        return nextSundays;
      };
  
        setSundays(getNextSundays());
  
      getdashboardinfo();
    }, []);
    
    const fname = localStorage.getItem("ccing_fname");

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };

      return (
              <div>
     <Header/>
     <div className="pc-container">
        <div className="pc-content">
          <a href="#"><img src="/assets/img/logo.png" width={'150'} alt="img" /></a>
          <br/>
          <br/>
          <div className="row">
            <div className="col-md-6 col-xxl-3">
              <div className="card bg-gray-800 dropbox-card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-white">Hello {fname}</h5>
                  </div>
                  <div className="mb-3">
                  <small className="text-white">You have made {cust_info.length} reservation(s) so far.</small>
                  </div>
                  <br/>
                  <small className="text-white">{mycurrentDay} {mycurrentMonth} {mycurrentYear}</small>
                  <div className="progress mt-2 bg-transparent" style={{height: '8px'}}>
                    <div className="progress-bar bg-danger" role="progressbar" style={{width: '15%'}} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} />
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: '20%'}} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
                    <div className="progress-bar bg-light-primary" role="progressbar" style={{width: '20%'}} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
                    <div className="progress-bar bg-success" role="progressbar" style={{width: '25%'}} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              </div>
              <div className="card bg-primary available-balance-card">
                <div className="card-body p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 text-white">Select Date</p>
                    </div>
                    <div className="dropdown">
                    {/* <select onChange={handleSelectChange} value={selectedSunday}>
                      <option value={''}>---</option> 
                     {sundays.map((sunday, index) => (
                        <option key={index} value={sunday.toISOString()}>
                          {sunday.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
                        </option>
                      ))} 
                    
                    </select> */}
                    <select value={selectedSunday} onChange={handleSundayChange}>
                      <option value={''}>---</option>
                      {sundays.map((sunday, index) => (
                        <option key={index} value={sunday.toISOString()}>
                          {sunday.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
                        </option>
                      ))}
                    </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-7 col-xl-8">
                      
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="analytics-tab-1-pane" role="tabpanel" aria-labelledby="analytics-tab-1" tabIndex={0}>
                          <div id="overview-chart-1" />
                        </div>
                        <div className="tab-pane fade" id="analytics-tab-2-pane" role="tabpanel" aria-labelledby="analytics-tab-2" tabIndex={0}>
                          <div id="overview-chart-2" />
                        </div>
                        <div className="tab-pane fade" id="analytics-tab-3-pane" role="tabpanel" aria-labelledby="analytics-tab-3" tabIndex={0}>
                          <div id="overview-chart-3" />
                        </div>
                        <div className="tab-pane fade" id="analytics-tab-4-pane" role="tabpanel" aria-labelledby="analytics-tab-4" tabIndex={0}>
                          <div id="overview-chart-4" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-xl-4">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <div className="avtar avtar-s bg-light-secondary">
                                <i className="pe pe-7s-menu f-20" />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="row g-1">
                                <div className="col-6">
                                  <p></p>
                                  <h6 className="mb-0">8:00am</h6>
                                </div>
                                <div className="col-6 text-end">
                                <button onClick={() => reserveBooking('8:00am')} className='btn btn-danger btn-sm'>Reserve</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <div className="avtar avtar-s bg-light-secondary">
                                <i className="pe pe-7s-menu f-20" />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="row g-1">
                              <div className="col-6">
                                  <p></p>
                                  <h6 className="mb-0">9:00am</h6>
                                </div>
                                <div className="col-6 text-end">
                                <button onClick={() => reserveBooking('9:00am')} className='btn btn-danger btn-sm'>Reserve</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <div className="avtar avtar-s bg-light-secondary">
                                <i className="pe pe-7s-menu f-20" />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="row g-1">
                              <div className="col-6">
                                  <p></p>
                                  <h6 className="mb-0">11:00am</h6>
                                </div>
                                <div className="col-6 text-end">
                                <button onClick={() => reserveBooking('11:00am')} className='btn btn-danger btn-sm'>Reserve</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <div className="avtar avtar-s bg-light-secondary">
                                <i className="pe pe-7s-menu f-20" />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="row g-1">
                              <div className="col-6">
                                  <p></p>
                                  <h6 className="mb-0">1:30pm</h6>
                                </div>
                                <div className="col-6 text-end">
                                <button onClick={() => reserveBooking('1:30pm')} className='btn btn-danger btn-sm'>Reserve</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-xxl-3 col-md-12">
              <div className="row">
              <br/>
            <br/>
            <br/>
              </div>
            </div>
           
           
          </div>
        </div>
      </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default Home;
