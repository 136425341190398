import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Checkout(){

  const [state , setState] = useState({
    phone: '',
    email: '',
    postcode: '',
    town: '',
    address: '',
    country: '',
    state: '',
    firstname: '',
    lastname: ''
  })
  // const loginUrl = "http://localhost:9200/api/login";
  const loginUrl = "https://justgymbackend.starlightsoftware.co.bw/api/login";
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }


      return (
     <div>
     <Header/>
     <div className="checkout-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="billing-info-wrap">
                <h3>Billing Details</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>First Name</label>
                      <input className="billing-address" type="text" value={state.firstname} onChange={handleChange} id="firstname" name="firstname" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Last Name</label>
                      <input className="billing-address" type="text" value={state.lastname} onChange={handleChange} id="lastname" name="lastname" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Email</label>
                      <input className="billing-address" type="text" value={state.email} onChange={handleChange} id="email" name="email" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Phone</label>
                      <input className="billing-address" type="text" value={state.phone} onChange={handleChange} id="phone" name="phone" />
                    </div>
                  </div>
                 
                  <div className="col-lg-12">
                    <div className="billing-select mb-4">
                      <label>Country</label>
                      <select  type="text" value={state.country} onChange={handleChange} id="country" name="country">
                        <option>Select a country</option>
                        <option value={'United Kingdom'}>United Kingdom</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="billing-info mb-4">
                      <label>Street Address</label>
                      <input className="billing-address" type="text" value={state.address} onChange={handleChange} id="address" name="address" />
                      <input type="text" placeholder="Apartment, suite, unit etc."  value={state.apartment} onChange={handleChange} id="apartment" name="apartment" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="billing-info mb-4">
                      <label>Town / City</label>
                      <input type="text"  value={state.town} onChange={handleChange} id="town" name="town" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>State / County</label>
                      <input type="text"  value={state.state} onChange={handleChange} id="state" name="state" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Postcode / ZIP</label>
                      <input type="text"   value={state.postcode} onChange={handleChange} id="postcode" name="postcode" />
                    </div>
                  </div>
                 
                
                </div>
                {/* <div className="checkout-account mb-30px">
                  <input className="checkout-toggle2 w-auto h-auto" type="checkbox" />
                  <label>Create an account?</label>
                </div> */}
                <div className="checkout-account-toggle open-toggle2 mb-30">
                  <input placeholder="Email address" type="email" />
                  <input placeholder="Password" type="password" />
                  <button className="btn-hover checkout-btn" type="submit">register</button>
                </div>
                <div className="additional-info-wrap">
                  <h4>Additional information</h4>
                  <div className="additional-info">
                    <label>Order notes</label>
                    <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message" defaultValue={""} />
                  </div>
                </div>
                <div className="checkout-account mt-25">
                  {/* <input className="checkout-toggle w-auto h-auto" type="checkbox" /> */}
                  {/* <label>Ship to a different address?</label> */}
                </div>
                
              </div>
            </div>
            <div className="col-lg-5 mt-md-30px mt-lm-30px ">
              <div className="your-order-area">
                <h3>Your order</h3>
                <div className="your-order-wrap gray-bg-4">
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Product</li>
                        <li>Total</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        <li><span className="order-middle-left">Product Name X 1</span> <span className="order-price">$100 </span></li>
                        <li><span className="order-middle-left">Product Name X 1</span> <span className="order-price">$100 </span></li>
                      </ul>
                    </div>
                    <div className="your-order-bottom">
                      <ul>
                        <li className="your-order-shipping">Shipping</li>
                        <li>Free shipping</li>
                      </ul>
                    </div>
                    <div className="your-order-total">
                      <ul>
                        <li className="order-total">Total</li>
                        <li>$100</li>
                      </ul>
                    </div>
                  </div>
                  <div className="payment-method">
                    <div className="payment-accordion element-mrg">
                      <div id="faq" className="panel-group">
                        <div className="panel panel-default single-my-account m-0">
                          <div className="panel-heading my-account-title">
                            <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-1" className="collapsed" aria-expanded="true">Direct bank transfer</a>
                            </h4>
                          </div>
                          <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
                            <div className="panel-body">
                              <p>Please send a check to Store Name, Store Street, Store Town,
                                Store State / County, Store Postcode.</p>
                            </div>
                          </div>
                        </div>
                        <div className="panel panel-default single-my-account m-0">
                          <div className="panel-heading my-account-title">
                            <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-2" aria-expanded="false" className="collapsed">Check payments</a></h4>
                          </div>
                          <div id="my-account-2" className="panel-collapse collapse" data-bs-parent="#faq">
                            <div className="panel-body">
                              <p>Please send a check to Store Name, Store Street, Store Town,
                                Store State / County, Store Postcode.</p>
                            </div>
                          </div>
                        </div>
                        <div className="panel panel-default single-my-account m-0">
                          <div className="panel-heading my-account-title">
                            <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-3">Cash on delivery</a></h4>
                          </div>
                          <div id="my-account-3" className="panel-collapse collapse" data-bs-parent="#faq">
                            <div className="panel-body">
                              <p>Please send a check to Store Name, Store Street, Store Town,
                                Store State / County, Store Postcode.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Place-order mt-25">
                  <a className="btn-hover" href="#">Place Order</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Checkout;
