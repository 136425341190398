const Codes = [
    {value: 'AA', label: 'AA'},
    {value: 'AAPL', label: 'AAPL'},
    {value: 'IBM', label: 'IBM'},
    {value: 'GE', label: 'GE'},
    {value: 'F', label: 'F'},
    {value: 'JPM', label: 'JPM'},
    {value: 'KO', label: 'KO'},
    {value: 'MCD', label: 'MCD'},
    {value: 'MSFT', label: 'MSFT'},
    {value: 'TSLA', label: 'TSLA'},
    {value: 'V', label: 'V'},
    {value: 'WMT', label: 'WMT'},
    {value: 'XOM', label: 'XOM'},
    {value: 'CVX', label: 'CVX'},
    {value: 'DIS', label: 'DIS'},
    {value: 'BA', label: 'BA'},
    {value: 'CAT', label: 'CAT'},
    {value: 'GS', label: 'GS'},
    {value: 'HD', label: 'HD'},
    {value: 'INTC', label: 'INTC'},
    {value: 'JNJ', label: 'JNJ'},
    {value: 'MMM', label: 'MMM'},
    {value: 'MRK', label: 'MRK'},
    {value: 'NKE', label: 'NKE'},
    {value: 'PG', label: 'PG'},
    {value: 'RTX', label: 'RTX'},
    {value: 'UNH', label: 'UNH'},
    {value: 'VZ', label: 'VZ'},
    {value: 'WBA', label: 'WBA'},
    {value: 'X', label: 'X'},
    {value: 'GM', label: 'GM'},
    {value: 'T', label: 'T'},
    {value: 'MO', label: 'MO'},
    {value: 'PEP', label: 'PEP'},
    {value: 'SLB', label: 'SLB'},
    {value: 'SO', label: 'SO'},
    {value: 'C', label: 'C'},
    {value: 'CVS', label: 'CVS'},
    {value: 'PFE', label: 'PFE'},
    {value: 'BRK.B', label: 'BRK.B'},
    {value: 'BMY', label: 'BMY'},
    {value: 'ABT', label: 'ABT'},
    {value: 'LMT', label: 'LMT'},
    {value: 'HON', label: 'HON'},
    {value: 'USB', label: 'USB'},
    {value: 'NEE', label: 'NEE'},
    {value: 'ORCL', label: 'ORCL'},
    {value: 'SPG', label: 'SPG'}
];

    export default Codes;
    
    
    
    
     
     
    
    
    